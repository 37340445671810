export const BONUS_LIST = {
  "0x0065058668b350bee2763d43d14ff293d916b106":	"3.9074092",
  "0x00845273fd2566e172ab6cc995666b57605dadde":	"1178.599176",
  "0x01e2b2bd0cb3723d7a1319b5c6130f2fd54ac3ea":	"44.23513047",
  "0x0202ed9ff0d505f9b064a610199a001cef9977bd":	"169.9609375",
  "0x02feb30219ab9ecbfe083189f72a318db6affc97":	"1421.716087",
  "0x037d462df1e10d6c4a1c629bebed89d4bc151e6b":	"320.1707528",
  "0x048039b1dc0e9ef2f027e755ed5c42e2556703ed":	"84.09594201",
  "0x0624b16e9920587dbcc5207d4fa43cf2fd9f0767":	"6.783293804",
  "0x065c85dcbeada4da3f4a389fcfc9665dffa1d490":	"92.55054248",
  "0x0799442ce0f90f8837fdeece82cd2735625b4bf9":	"169.9242353",
  "0x07f5dd1fb0a005f89ac536787dd6c03ed16bd205":	"161.3552901",
  "0x0a2809076b654d6951e41b03568f16717a50af4a":	"170.8925619",
  "0x0a51351684e75c3e23e51d7433ed5a10b5db42c4":	"567.0681639",
  "0x0b81747f504dfc906a215e301d8b8ad82e44cbd2":	"17.9306009",
  "0x0c470f23b09847fc99e20b63aa9b0c3db13bacf7":	"8.491305756",
  "0x0d94b6e4c2aa9383964986020b3534d34885f700":	"493.5310405",
  "0x0dc8a678dfe86683c3c3f11d5da65c35615672f4":	"118.4331379",
  "0x1011bab49dced9b996c436bee21c664cf3e0d2ca":	"41.3363357",
  "0x10a021814291ceb7a8e3b71225da036ada95ce6f":	"45.07532238",
  "0x10bb72afd656d8f67f283f5d839f502d3a5cc264":	"9.437210681",
  "0x11d0cb5c690bc838efc52c621f6b48040dd000f7":	"16.9385535",
  "0x1202fba35cc425c07202baa4b17fa9a37d2dbebb":	"319.7561009",
  "0x139596f71b5d26d467ed1819a535a9190ea3ed0e":	"443.8123348",
  "0x15d3435f25eb464ea86853037da94137bd76ef70":	"850.6067811",
  "0x163d560b201c984206df7d3782f7cdc86c6e1135":	"23.45290354",
  "0x16c326ffa921115e6ab8192167c0c4da7c4129da":	"321.1049867",
  "0x178a61498172d59bad609b7ad2469dd555642151":	"679.9038717",
  "0x1835243a84ed1c6e663637f2fd70bfe9a3a84683":	"67.87511818",
  "0x1ab2fa94d7cf45a0c7a6cd01235c0d9310922aa3":	"115.8168076",
  "0x1ba3a721b96784c57e35bb78eec025200147eade":	"33.71219596",
  "0x1babe5c073d407ba7418216938078c0f8c6212dc":	"25.78507187",
  "0x1c74b67f9c6dd986e4735b3e2b34bc1eaa828a20":	"253.3727165",
  "0x1cc972ff969689873f8010bdd0cf3dc30618397e":	"169.4280109",
  "0x1ccd11ec7e6172f006ec623486936743d7a74d23":	"20.78145564",
  "0x1dfd753d0b19b3f0c36a2a80808cfb98afffa290":	"241.3584121",
  "0x1e121993b4a8bc79d18a4c409db84c100fff25f5":	"42.52550521",
  "0x1f3555e9572af36c2a0f0ec54fa929c5f7d04223":	"346.917138",
  "0x204636753b992df51dcdd983abd175f79e29e9e5":	"28.95893039",
  "0x209cdf66f6e91dbfa28120dec10b18d7f461e800":	"57.83342959",
  "0x22ba454a306db9b252e33ea252b3588d2d264996":	"10.22638628",
  "0x2526a6efeef588bdfb0d572d8c373dd71ef4e4f4":	"22.66721734",
  "0x26c795a469719c032473687c8cc9e156b6fea660":	"918.9828607",
  "0x2791811bc893ce6997357c4f0c2a494773762294":	"10.17814287",
  "0x29b08162a1ff8a2c8745aa19f055b816afe0a898":	"46.00044132",
  "0x2aeec5a561ad26616867074178811a7022bd0937":	"13.54227056",
  "0x31ea2730a76c7d480bba0267eb91d02a3087ea88":	"13.96536355",
  "0x32759f682532dc6de41841b9fded23e136b1c5ff":	"18.10456325",
  "0x32dbc2201d6804b743e353fbbcc767598ba56901":	"1971.462994",
  "0x35c3ccbbfcd78f2b3581260c5464a74e58d27049":	"15.29491657",
  "0x376a6efe8e98f3ae2af230b3d45b8cc5e962bc27":	"5095.950386",
  "0x378d1da3180db919a0a79c055ec80e4183e8f704":	"76.73565094",
  "0x39253c7996894b72e635738754edd39061d2a7ab":	"67.77819023",
  "0x398890e4850e31e74cb2266949f53557ec35eac1":	"45.47439898",
  "0x3b138fc7ec06b2a44565994cfde5134a75915995":	"23.13799077",
  "0x3c9002cdb61c56b89467d94a2d93235dfd698089":	"1693.425237",
  "0x3cfecad11f3de1c2d67ec6fdf7fecdc38676f2c7":	"674.6709457",
  "0x3da9d70305b83db1f545a5d50b19208b93cd0c21":	"7155.756702",
  "0x3dd4234daeefbabceaa6068c04c3f75f19aa2cda":	"554.7382813",
  "0x3f9280700b9d38ae84d4eff141edd9c9b4be4bc0":	"16.96377378",
  "0x42d62a3277cd756396d0c40057b1e1652bb143c3":	"77.41465719",
  "0x42dc6ef1bdc03b4048184e388ae1130120763a9e":	"47.71530039",
  "0x437ec964ecfb746749141a775f56f61330fc8e54":	"3774.318453",
  "0x448a6e3e4c02df62fc8d9817f9817add26d706d0":	"85.15515517",
  "0x4497714fb2df95b104d568877b994e10153f8f14":	"2288.100164",
  "0x48defc77af8d69d12d3e58f9b38350f3e84bb089":	"101.2501037",
  "0x4a8aea9c148f821db030b2d7f4a565b30fede78e":	"242.4970247",
  "0x4bda90f0127df84c12c36cb443da4c852ef26f4e":	"30.3521205",
  "0x4e27452b03dbfdbb36e796cd0167a30b242871d2":	"68.27202277",
  "0x4e48bd7bb7d4a6ea48d900f1ad2527ee38b24949":	"82.56207961",
  "0x54008ab19ae9d2ab8b3751b3be11bb562af1a578":	"30.44395451",
  "0x54689fd2d989c0c0498c87794b93309bc3872c80":	"118.760736",
  "0x58606607df8fe77c39fbc534c48da4bfa44856c3":	"15.22461425",
  "0x589757c9346ae769b400274803c3dfad24ffd34e":	"231.7057646",
  "0x59591576d0ae8c1bf05c02b9f0b7eeca7156378e":	"110.3651663",
  "0x5984bb82f11171cb1dc2287e2a6935c44d491538":	"5167.976936",
  "0x5a2ab96e424fd56785705aedc13903967fec0244":	"81.72697406",
  "0x5c16afe06cbb06afcc34431820f31f50a93c115e":	"137.3481604",
  "0x5ce259aedcd14038ad2c35ecaa7b6b2974d339ac":	"334.5685083",
  "0x5f799f365fa8a2b60ac0429c48b153ca5a6f0cf8":	"476.5314275",
  "0x62abe7f56b87e9c41a5aa0b34516c9b21ee7b725":	"579.1419304",
  "0x64ea0fa30efed0d53900fcd4fb10c6c47262f0d9":	"115.9946452",
  "0x685b5623db05e2298ad745124e425e5c8c687edb":	"19.17935434",
  "0x6b29f22ddbf39ad816e75d1455f99c03224ac9e9":	"229.5889186",
  "0x6bff5e48fa09446ee2ddd46f3635ed117c055db7":	"15.17096022",
  "0x702e9e66a100881039be7bbadacf1ba607c97f6b":	"639.6188783",
  "0x70cd1e5abd2714ce7abc3a4cfe82700c203c9bb4":	"34.52758501",
  "0x77d2822cc311bf6972412d98c9d77540bc0b0837":	"22.61287519",
  "0x7817c57fdd423e86177f7d3a179717884e67eac5":	"20.06437749",
  "0x7a3c62ea4e60d458a7ef7bdf4a726a0a7f38e00f":	"13.89801691",
  "0x7ab066bcd945264e1b1c8d451cb3517c973e676c":	"524.2254239",
  "0x7c218fc943e54b82ed027cec922d364859bf1ead":	"33.93391975",
  "0x7cb1fa27a7f5fabc055e1cdbda3821f249300159":	"65.78744402",
  "0x85b7fb279a888c56163275df5a2caa03cbe467fd":	"9036.357572",
  "0x862c6f0373ac129fc66a324b234943139ca10c92":	"8472.95216",
  "0x887c3599c4826f7b3cde82003b894430f27d5b92":	"93.83816263",
  "0x88bf5a2e82510847e5dcbf33f44a9f611f1c1df5":	"467.8550813",
  "0x8f678841ebd0202bc2e5c2d25b4170b5426abfa3":	"67.82202045",
  "0x93dcb826190ccd488ec4b5ddc7f94c30dcb56b2c":	"11.77743753",
  "0x94aff6c2c005567aa0e2a27f77701bb1b6679b6b":	"168.4819567",
  "0x94bab67493ac27a06ddacef16f79f70ec9840681":	"28.9542019",
  "0x952815ea349a0c10db5d854a16a27d768e85053e":	"24.3614864",
  "0x95861c41e7767dc737bca90b175ad51e5f7b9ada":	"303.8314071",
  "0x96f39f4dab689af8d301d81564c5505a31a23010":	"67.65057463",
  "0x9b667fa9ef908407dc90ad0274039fa2fd0007b3":	"9.223366363",
  "0x9bc73f3c24a78d6043732a5ff471318c6cf2f4c7":	"11.72510681",
  "0x9c7b84be5d69bb41a718a4af921e44730a277f90":	"165.3429435",
  "0x9ea278beddb85382ef23c0ff42d1c0d4d9d6d296":	"101.912817",
  "0x9f169c2189a2d975c18965de985936361b4a9de9":	"1.292897589",
  "0xa03d1648be58e6957c81c1c201236e189b6ee6af":	"1191.698329",
  "0xa0a6dc36041fb386378458006fecbddd02555ddd":	"45.02553213",
  "0xa0fe21025d98df3ad2712f3307352ed20a33b75a":	"84.96432509",
  "0xa37d1bfc67f20ac3d88a3d50e2d315a95161d89c":	"3127.844594",
  "0xa4dd9f99359e09a3a6bc0bc4f685df8b173c9c8d":	"1867.314842",
  "0xa618f8841e70b0543a921768ea5b229ecd9a4ba8":	"163.8735373",
  "0xa7e6f51af4fbd7d1f4d95764bbc965cec4ad5b91":	"16.91332194",
  "0xa95c1601c4a7084c90f0a5eaa09af75df5d40be8":	"11.83998749",
  "0xab769309ebceeda984e666ab727b36211ba02a8a":	"1.696459018",
  "0xac04fa6c41580f24687cce310ea9529e87237658":	"98.68353576",
  "0xac650e37df74899dda0ca0aac65cad6768458978":	"171.2171668",
  "0xacbabcf02fe30dc5873317496b4d836f605e00d3":	"17.05750055",
  "0xaf0acd71df2e5f3d637ead63fe3fe3420eec43c7":	"918.801502",
  "0xaf1ac2b07c6f750ca2ab47595f0a4b5fc4f5685d":	"168.3385509",
  "0xb07fcf89a7050e1e7233cff5038b8166b6828146":	"1396.057724",
  "0xb1a8f15b687c3c022375b56ac4917420c2ef3cbe":	"86.8626057",
  "0xb4628f71581f45303e551110b95615d807cfe628":	"10.19677156",
  "0xb9e723b4d944fd0000c6590e327d48fd735af4d1":	"226.5258247",
  "0xbb00a5a2ae7450818f51d3a81e2e74ac29f52dc1":	"170.2076088",
  "0xbb00f2e53888e60974110d68f1060e5eaab34790":	"137.0556492",
  "0xbd0de86bd18e1546d17fb8800bf7675d4e86ee65":	"1697.285141",
  "0xc12a39668ed7257410a598cb8fb6e9bcdf594620":	"4237.590888",
  "0xc1bebd66dfde46788c78f38b6f402d2ef17a9f9c":	"266.3101334",
  "0xc23203e8ad67fb13388bf58d513fb42b490c9dc3":	"199.616606",
  "0xc2de5c0d250263da8989893760dd0971af7d87b0":	"4165.880056",
  "0xc3d6698f86837745cf79d66e844b9662c4183a51":	"673.4053716",
  "0xc401e5708ea5278c0f3895bcdbe5d0bdcabc28cc":	"84.01292582",
  "0xc4d5a51dab2cb9c41e9087fbb6bb4585175a6d4c":	"34.74504228",
  "0xc4f107089a02e5b3e52b99f73d19edb99e68dcc9":	"23.78621568",
  "0xc6ed78e47ee4a0a374bd0a6c758150b91da27867":	"147.6636757",
  "0xc72924e93e79a2c8e4826aa8535585fbf59ff952":	"1849.952674",
  "0xc7b0c83b1ffb301a7d38c0a120c7bdd69c81417a":	"134.9977365",
  "0xcd270ea8ff7905aeb0b89a840c9e6beb5a034476":	"452.4094",
  "0xce8d6b801adbc0099d3fe61a4fde9e49e8551d93":	"30.29078652",
  "0xd18c96b6cd70987189b95e02b27d98e681bba100":	"451.8273542",
  "0xd47016d69cf5fbf3ee49a8cc1f061de2c276e343":	"86.86120018",
  "0xd4ff73b1fefa1c2180b8683fe400604f341e8784":	"2.444555643",
  "0xd61605615c03642244a8855d240f4d01d9be6c9a":	"5.08846867",
  "0xd86567f4133481de5f4b6c79000e63508a34a89e":	"337.7643235",
  "0xd9737fec26e1c5fcc1beab1877c239c1623808b7":	"51.05261746",
  "0xda6c99ef3ccec47bd5efa33ba68a62f481af2203":	"2.698064206",
  "0xdd094c8f7fcede76a7d724068fd73acdf9b9ea80":	"3870.440818",
  "0xdec0b52b61465fbe2116e2b997d6fe79bb344990":	"977.8834814",
  "0xe0153aeccc9cf3dd2a2ce151c6bd61f8f1990cc6":	"42.81907717",
  "0xe062b15b567083486769c8c65ffb0d6c10f40ac3":	"4.346544115",
  "0xe1e0f8c1fee4bac8219711fb0f4333df1cafe2bf":	"1179.084639",
  "0xe26f54284c451ec4c0f65eb973f737f7962a5a3b":	"40.60415458",
  "0xe40a1135b60bc2c2dc77c500a295347f3bbad5e8":	"832.1305483",
  "0xe68e10a941c0ccecdd6eed8650a6e82dd41afb7f":	"11.95027022",
  "0xe9b80b4cf85d7709583dff27c1f51b289cd1e152":	"127.4432828",
  "0xe9c9d37cee4e01db415ac9988762a0c59151c8a4":	"316.994942",
  "0xeb0f9b1b00b0688d3b507cd03ae6b66a05e2686f":	"0.579084038",
  "0xeb63b159d15e1cb670d540e268403da6a5b3239e":	"11.8692966",
  "0xebcb75834c1211ce116706643a857f4b56b8cd76":	"17.35053132",
  "0xed2c95209938ec8d2430ff39252f13ed07992904":	"3.045398788",
  "0xed56bc4d7dfc7e0cc3f5f3314141a054cb457192":	"459.3401367",
  "0xedba8f374d309885f43d99852bac0ccf9eadb36a":	"285.8734495",
  "0xeeaf22484b92e95160b0e4da0fd239c1ea1e94f2":	"67.30019879",
  "0xeef1b2fa1743e9215e1d8881d52b886618453c5e":	"946.1611164",
  "0xf21b2328c6f6941f8af40d98860f1681c31f3ef2":	"93.57352583",
  "0xf3723a2da2371d1e276365e924bcdf88cb77d2dd":	"523.1508811",
  "0xf3fbb2c0a711529ef9b81fe59a5ef5b8f1e0eb27":	"40.43174726",
  "0xf72cd97a579fb4b06588812ad3bf3f9c739f02a3":	"101.4889024",
  "0xf77e2fc47b68802f1f29014aedcbba85b9fc2bc9":	"16.17462426",
  "0xf8a2d291ce72bd83d010151ca1c14580ef05181f":	"87.20130786",
  "0xfa6eb19ecbd862f0de6ec12bba05f92b77b0b08f":	"1639.420321",
  "0xfab48971b60fc457e9a6723b7f662683b9bd9237":	"136.1792511",
  "0xfba4d8b989058c8662e641d6e1eee47a46b2d6d6":	"67.31119908",
  "0xfbcde59cfddb74fe367876400f1b74ce459a2d78":	"84.96247796",
  "0xfc051acfc544583511333240ec9f1436d0213c74":	"27.56272894",
  "0xfe362f889a2c843708daf7d32ed70ec6188a3a47":	"490.5517954",
  "0xff059d87096d1edf40ab0af9f1bd32efebcfb386":	"169.0113077"
}

export const GLP_LIST = {
  "0x0065058668b350bee2763d43d14ff293d916b106":	"67.475685",
  "0x00845273fd2566e172ab6cc995666b57605dadde":	"20352.817522",
  "0x01e2b2bd0cb3723d7a1319b5c6130f2fd54ac3ea":	"763.881018",
  "0x0202ed9ff0d505f9b064a610199a001cef9977bd":	"2934.996069",
  "0x02feb30219ab9ecbfe083189f72a318db6affc97":	"24551.118550",
  "0x037d462df1e10d6c4a1c629bebed89d4bc151e6b":	"5528.916907",
  "0x048039b1dc0e9ef2f027e755ed5c42e2556703ed":	"1452.223451",
  "0x0624b16e9920587dbcc5207d4fa43cf2fd9f0767":	"117.138332",
  "0x065c85dcbeada4da3f4a389fcfc9665dffa1d490":	"1598.222994",
  "0x0799442ce0f90f8837fdeece82cd2735625b4bf9":	"2934.362271",
  "0x07f5dd1fb0a005f89ac536787dd6c03ed16bd205":	"2786.388149",
  "0x0a2809076b654d6951e41b03568f16717a50af4a":	"2951.083966",
  "0x0a51351684e75c3e23e51d7433ed5a10b5db42c4":	"9792.502067",
  "0x0b81747f504dfc906a215e301d8b8ad82e44cbd2":	"309.637284",
  "0x0c470f23b09847fc99e20b63aa9b0c3db13bacf7":	"146.633393",
  "0x0d94b6e4c2aa9383964986020b3534d34885f700":	"8522.615167",
  "0x0dc8a678dfe86683c3c3f11d5da65c35615672f4":	"2045.180494",
  "0x1011bab49dced9b996c436bee21c664cf3e0d2ca":	"713.822744",
  "0x10a021814291ceb7a8e3b71225da036ada95ce6f":	"778.389999",
  "0x10bb72afd656d8f67f283f5d839f502d3a5cc264":	"162.967895",
  "0x11d0cb5c690bc838efc52c621f6b48040dd000f7":	"292.505964",
  "0x1202fba35cc425c07202baa4b17fa9a37d2dbebb":	"5521.756427",
  "0x139596f71b5d26d467ed1819a535a9190ea3ed0e":	"7664.040203",
  "0x15d3435f25eb464ea86853037da94137bd76ef70":	"14688.831417",
  "0x163d560b201c984206df7d3782f7cdc86c6e1135":	"405.000000",
  "0x16c326ffa921115e6ab8192167c0c4da7c4129da":	"5545.049865",
  "0x178a61498172d59bad609b7ad2469dd555642151":	"11741.022495",
  "0x1835243a84ed1c6e663637f2fd70bfe9a3a84683":	"1172.111710",
  "0x1ab2fa94d7cf45a0c7a6cd01235c0d9310922aa3":	"1999.999999",
  "0x1ba3a721b96784c57e35bb78eec025200147eade":	"582.164138",
  "0x1babe5c073d407ba7418216938078c0f8c6212dc":	"445.273400",
  "0x1c74b67f9c6dd986e4735b3e2b34bc1eaa828a20":	"4375.404947",
  "0x1cc972ff969689873f8010bdd0cf3dc30618397e":	"2925.793145",
  "0x1ccd11ec7e6172f006ec623486936743d7a74d23":	"358.867699",
  "0x1dfd753d0b19b3f0c36a2a80808cfb98afffa290":	"4167.934121",
  "0x1e121993b4a8bc79d18a4c409db84c100fff25f5":	"734.358097",
  "0x1f3555e9572af36c2a0f0ec54fa929c5f7d04223":	"5990.790891",
  "0x204636753b992df51dcdd983abd175f79e29e9e5":	"500.081655",
  "0x209cdf66f6e91dbfa28120dec10b18d7f461e800":	"998.705297",
  "0x22ba454a306db9b252e33ea252b3588d2d264996":	"176.595893",
  "0x2526a6efeef588bdfb0d572d8c373dd71ef4e4f4":	"391.432259",
  "0x26c795a469719c032473687c8cc9e156b6fea660":	"15869.594054",
  "0x2791811bc893ce6997357c4f0c2a494773762294":	"175.762794",
  "0x29b08162a1ff8a2c8745aa19f055b816afe0a898":	"794.365555",
  "0x2aeec5a561ad26616867074178811a7022bd0937":	"233.856740",
  "0x31ea2730a76c7d480bba0267eb91d02a3087ea88":	"241.162986",
  "0x32759f682532dc6de41841b9fded23e136b1c5ff":	"312.641380",
  "0x32dbc2201d6804b743e353fbbcc767598ba56901":	"34044.505885",
  "0x35c3ccbbfcd78f2b3581260c5464a74e58d27049":	"264.122572",
  "0x376a6efe8e98f3ae2af230b3d45b8cc5e962bc27":	"88000.187394",
  "0x378d1da3180db919a0a79c055ec80e4183e8f704":	"1325.121155",
  "0x39253c7996894b72e635738754edd39061d2a7ab":	"1170.437895",
  "0x398890e4850e31e74cb2266949f53557ec35eac1":	"785.281513",
  "0x3b138fc7ec06b2a44565994cfde5134a75915995":	"399.561881",
  "0x3c9002cdb61c56b89467d94a2d93235dfd698089":	"29243.168972",
  "0x3cfecad11f3de1c2d67ec6fdf7fecdc38676f2c7":	"11650.656923",
  "0x3da9d70305b83db1f545a5d50b19208b93cd0c21":	"123570.263250",
  "0x3dd4234daeefbabceaa6068c04c3f75f19aa2cda":	"9579.581630",
  "0x3f9280700b9d38ae84d4eff141edd9c9b4be4bc0":	"292.941485",
  "0x42d62a3277cd756396d0c40057b1e1652bb143c3":	"1336.846677",
  "0x42dc6ef1bdc03b4048184e388ae1130120763a9e":	"823.978857",
  "0x437ec964ecfb746749141a775f56f61330fc8e54":	"65177.387132",
  "0x448a6e3e4c02df62fc8d9817f9817add26d706d0":	"1470.514633",
  "0x4497714fb2df95b104d568877b994e10153f8f14":	"39512.402590",
  "0x48defc77af8d69d12d3e58f9b38350f3e84bb089":	"1748.452678",
  "0x4a8aea9c148f821db030b2d7f4a565b30fede78e":	"4187.596425",
  "0x4bda90f0127df84c12c36cb443da4c852ef26f4e":	"524.140168",
  "0x4e27452b03dbfdbb36e796cd0167a30b242871d2":	"1178.965716",
  "0x4e48bd7bb7d4a6ea48d900f1ad2527ee38b24949":	"1425.735717",
  "0x54008ab19ae9d2ab8b3751b3be11bb562af1a578":	"525.726017",
  "0x54689fd2d989c0c0498c87794b93309bc3872c80":	"2050.837671",
  "0x58606607df8fe77c39fbc534c48da4bfa44856c3":	"262.908546",
  "0x589757c9346ae769b400274803c3dfad24ffd34e":	"4001.245922",
  "0x59591576d0ae8c1bf05c02b9f0b7eeca7156378e":	"1905.857511",
  "0x5984bb82f11171cb1dc2287e2a6935c44d491538":	"89243.988722",
  "0x5a2ab96e424fd56785705aedc13903967fec0244":	"1411.314571",
  "0x5c16afe06cbb06afcc34431820f31f50a93c115e":	"2371.817411",
  "0x5ce259aedcd14038ad2c35ecaa7b6b2974d339ac":	"5777.546718",
  "0x5f799f365fa8a2b60ac0429c48b153ca5a6f0cf8":	"8229.054786",
  "0x62abe7f56b87e9c41a5aa0b34516c9b21ee7b725":	"10000.999724",
  "0x64ea0fa30efed0d53900fcd4fb10c6c47262f0d9":	"2003.071015",
  "0x685b5623db05e2298ad745124e425e5c8c687edb":	"331.201571",
  "0x6b29f22ddbf39ad816e75d1455f99c03224ac9e9":	"3964.690849",
  "0x6bff5e48fa09446ee2ddd46f3635ed117c055db7":	"261.982013",
  "0x702e9e66a100881039be7bbadacf1ba607c97f6b":	"11045.355015",
  "0x70cd1e5abd2714ce7abc3a4cfe82700c203c9bb4":	"596.244806",
  "0x77d2822cc311bf6972412d98c9d77540bc0b0837":	"390.493844",
  "0x7817c57fdd423e86177f7d3a179717884e67eac5":	"346.484727",
  "0x7a3c62ea4e60d458a7ef7bdf4a726a0a7f38e00f":	"240.000000",
  "0x7ab066bcd945264e1b1c8d451cb3517c973e676c":	"9052.665753",
  "0x7c218fc943e54b82ed027cec922d364859bf1ead":	"585.993008",
  "0x7cb1fa27a7f5fabc055e1cdbda3821f249300159":	"1136.060394",
  "0x85b7fb279a888c56163275df5a2caa03cbe467fd":	"156045.702849",
  "0x862c6f0373ac129fc66a324b234943139ca10c92":	"146316.451570",
  "0x887c3599c4826f7b3cde82003b894430f27d5b92":	"1620.458456",
  "0x88bf5a2e82510847e5dcbf33f44a9f611f1c1df5":	"8079.225995",
  "0x8f678841ebd0202bc2e5c2d25b4170b5426abfa3":	"1171.194783",
  "0x93dcb826190ccd488ec4b5ddc7f94c30dcb56b2c":	"203.380455",
  "0x94aff6c2c005567aa0e2a27f77701bb1b6679b6b":	"2909.456066",
  "0x94bab67493ac27a06ddacef16f79f70ec9840681":	"500.000000",
  "0x952815ea349a0c10db5d854a16a27d768e85053e":	"420.690000",
  "0x95861c41e7767dc737bca90b175ad51e5f7b9ada":	"5246.758453",
  "0x96f39f4dab689af8d301d81564c5505a31a23010":	"1168.234146",
  "0x9b667fa9ef908407dc90ad0274039fa2fd0007b3":	"159.275092",
  "0x9bc73f3c24a78d6043732a5ff471318c6cf2f4c7":	"202.476774",
  "0x9c7b84be5d69bb41a718a4af921e44730a277f90":	"2855.249544",
  "0x9ea278beddb85382ef23c0ff42d1c0d4d9d6d296":	"1759.896842",
  "0x9f169c2189a2d975c18965de985936361b4a9de9":	"22.326597",
  "0xa03d1648be58e6957c81c1c201236e189b6ee6af":	"20579.022224",
  "0xa0a6dc36041fb386378458006fecbddd02555ddd":	"777.530189",
  "0xa0fe21025d98df3ad2712f3307352ed20a33b75a":	"1467.219255",
  "0xa37d1bfc67f20ac3d88a3d50e2d315a95161d89c":	"54013.655847",
  "0xa4dd9f99359e09a3a6bc0bc4f685df8b173c9c8d":	"32246.007823",
  "0xa618f8841e70b0543a921768ea5b229ecd9a4ba8":	"2829.874881",
  "0xa7e6f51af4fbd7d1f4d95764bbc965cec4ad5b91":	"292.070249",
  "0xa95c1601c4a7084c90f0a5eaa09af75df5d40be8":	"204.460609",
  "0xab769309ebceeda984e666ab727b36211ba02a8a":	"29.295558",
  "0xac04fa6c41580f24687cce310ea9529e87237658":	"1704.131513",
  "0xac650e37df74899dda0ca0aac65cad6768458978":	"2956.689454",
  "0xacbabcf02fe30dc5873317496b4d836f605e00d3":	"294.560019",
  "0xaf0acd71df2e5f3d637ead63fe3fe3420eec43c7":	"15866.462234",
  "0xaf1ac2b07c6f750ca2ab47595f0a4b5fc4f5685d":	"2906.979641",
  "0xb07fcf89a7050e1e7233cff5038b8166b6828146":	"24108.033248",
  "0xb1a8f15b687c3c022375b56ac4917420c2ef3cbe":	"1500.000000",
  "0xb4628f71581f45303e551110b95615d807cfe628":	"176.084487",
  "0xb9e723b4d944fd0000c6590e327d48fd735af4d1":	"3911.795349",
  "0xbb00a5a2ae7450818f51d3a81e2e74ac29f52dc1":	"2939.255749",
  "0xbb00f2e53888e60974110d68f1060e5eaab34790":	"2366.766138",
  "0xbd0de86bd18e1546d17fb8800bf7675d4e86ee65":	"29309.824304",
  "0xc12a39668ed7257410a598cb8fb6e9bcdf594620":	"73177.477004",
  "0xc1bebd66dfde46788c78f38b6f402d2ef17a9f9c":	"4598.816682",
  "0xc23203e8ad67fb13388bf58d513fb42b490c9dc3":	"3447.109451",
  "0xc2de5c0d250263da8989893760dd0971af7d87b0":	"71939.127700",
  "0xc3d6698f86837745cf79d66e844b9662c4183a51":	"11628.802166",
  "0xc401e5708ea5278c0f3895bcdbe5d0bdcabc28cc":	"1450.789873",
  "0xc4d5a51dab2cb9c41e9087fbb6bb4585175a6d4c":	"600.000000",
  "0xc4f107089a02e5b3e52b99f73d19edb99e68dcc9":	"410.755851",
  "0xc6ed78e47ee4a0a374bd0a6c758150b91da27867":	"2549.952443",
  "0xc72924e93e79a2c8e4826aa8535585fbf59ff952":	"31946.186603",
  "0xc7b0c83b1ffb301a7d38c0a120c7bdd69c81417a":	"2331.228762",
  "0xcd270ea8ff7905aeb0b89a840c9e6beb5a034476":	"7812.499919",
  "0xce8d6b801adbc0099d3fe61a4fde9e49e8551d93":	"523.081013",
  "0xd18c96b6cd70987189b95e02b27d98e681bba100":	"7802.448774",
  "0xd47016d69cf5fbf3ee49a8cc1f061de2c276e343":	"1499.975728",
  "0xd4ff73b1fefa1c2180b8683fe400604f341e8784":	"42.214178",
  "0xd61605615c03642244a8855d240f4d01d9be6c9a":	"87.870988",
  "0xd86567f4133481de5f4b6c79000e63508a34a89e":	"5832.734133",
  "0xd9737fec26e1c5fcc1beab1877c239c1623808b7":	"881.609820",
  "0xda6c99ef3ccec47bd5efa33ba68a62f481af2203":	"46.591928",
  "0xdd094c8f7fcede76a7d724068fd73acdf9b9ea80":	"66837.290683",
  "0xdec0b52b61465fbe2116e2b997d6fe79bb344990":	"16886.728301",
  "0xe0153aeccc9cf3dd2a2ce151c6bd61f8f1990cc6":	"739.427688",
  "0xe062b15b567083486769c8c65ffb0d6c10f40ac3":	"75.058952",
  "0xe1e0f8c1fee4bac8219711fb0f4333df1cafe2bf":	"20361.200819",
  "0xe26f54284c451ec4c0f65eb973f737f7962a5a3b":	"701.178964",
  "0xe40a1135b60bc2c2dc77c500a295347f3bbad5e8":	"14369.771807",
  "0xe68e10a941c0ccecdd6eed8650a6e82dd41afb7f":	"206.365043",
  "0xe9b80b4cf85d7709583dff27c1f51b289cd1e152":	"2200.773539",
  "0xe9c9d37cee4e01db415ac9988762a0c59151c8a4":	"5474.074939",
  "0xeb0f9b1b00b0688d3b507cd03ae6b66a05e2686f":	"10.000000",
  "0xeb63b159d15e1cb670d540e268403da6a5b3239e":	"204.966737",
  "0xebcb75834c1211ce116706643a857f4b56b8cd76":	"299.620265",
  "0xed2c95209938ec8d2430ff39252f13ed07992904":	"52.589928",
  "0xed56bc4d7dfc7e0cc3f5f3314141a054cb457192":	"7932.184390",
  "0xedba8f374d309885f43d99852bac0ccf9eadb36a":	"4936.648754",
  "0xeeaf22484b92e95160b0e4da0fd239c1ea1e94f2":	"1162.183628",
  "0xeef1b2fa1743e9215e1d8881d52b886618453c5e":	"16338.925861",
  "0xf21b2328c6f6941f8af40d98860f1681c31f3ef2":	"1615.888536",
  "0xf3723a2da2371d1e276365e924bcdf88cb77d2dd":	"9034.109848",
  "0xf3fbb2c0a711529ef9b81fe59a5ef5b8f1e0eb27":	"698.201722",
  "0xf72cd97a579fb4b06588812ad3bf3f9c739f02a3":	"1752.576410",
  "0xf77e2fc47b68802f1f29014aedcbba85b9fc2bc9":	"279.313937",
  "0xf8a2d291ce72bd83d010151ca1c14580ef05181f":	"1505.848929",
  "0xfa6eb19ecbd862f0de6ec12bba05f92b77b0b08f":	"28310.576932",
  "0xfab48971b60fc457e9a6723b7f662683b9bd9237":	"2351.631925",
  "0xfba4d8b989058c8662e641d6e1eee47a46b2d6d6":	"1162.373588",
  "0xfbcde59cfddb74fe367876400f1b74ce459a2d78":	"1467.187358",
  "0xfc051acfc544583511333240ec9f1436d0213c74":	"475.971139",
  "0xfe362f889a2c843708daf7d32ed70ec6188a3a47":	"8471.167624",
  "0xff059d87096d1edf40ab0af9f1bd32efebcfb386":	"2918.597243"
}
